
import {
    IonButton,
    IonCol,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
    IonSearchbar,
    IonSpinner,
    IonText,
    IonContent
} from '@ionic/vue';
import {ellipse, square, triangle} from 'ionicons/icons';
import {defineComponent} from 'vue'
import {Juror, juryList} from '@/util/JuryService';
import Header from "@/components/Header.vue";

export default defineComponent({
    name: 'Tabs',
    components: {
        Header,
        IonPage,
        IonSearchbar,
        IonText,
        IonGrid,
        IonRow,
        IonSpinner,
        IonButton,
        IonList,
        IonItem,
        IonInput,
        IonLabel,
        IonCol,
        IonContent,

    },
    setup() {
        return {
            ellipse,
            square,
            triangle,
        }
    },
    data() {
        return {
            result: '',
            error: '',

            selectedJuror: {} as any,
            jurorSearch: '' as string,
            step: 1 as number,
            pw: '' as string,
        }
    },
    methods: {
        onDecode (result: any) {
            this.result = result
        },
        async onInit (promise: any) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permission"
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                } else if (error.name === 'InsecureContextError') {
                    this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                } else {
                    this.error = `ERROR: Camera error (${error.name})`;
                }
            }
        },
        selectJuror(j: object) {
            this.selectedJuror = j
            this.step = 2
        },
        verifyUser(juror: Juror) {
            juror.logInJuror()
            this.selectedJuror = {} as any;
            this.jurorSearch = '' as string;
            this.step = 1 as number;
            this.pw = '' as string;
        },
    },
    computed: {
        test() {
            return juryList.value
        },

        getJurors(): any {
            return juryList.value.filter((juror: any) => juror._firstName.toLowerCase().includes(this.jurorSearch.toLowerCase()) || juror._lastName.toLowerCase().includes(this.jurorSearch.toLowerCase()))
        },

        verifyPassword(): boolean {
            if (this.step === 2 && this.selectedJuror) {
                return this.selectedJuror.verifyPassword(this.pw, this.selectedJuror)
            }
            return true
        }
    },
})
